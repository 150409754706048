import React from "react"
import Awesome from "./awesome"
import T from "./translator"
import FrontmatterPageContext from "~context/FakePageContext"

import { MdCalendarToday } from "react-icons/md"

const LastUpdatedDate = () => {
  const fm = React.useContext(FrontmatterPageContext)

  let { last_updated, hide_date } = fm

  if (hide_date) {
    // nope!
    return <></>
  }

  if (!last_updated) {
    // last_updated not found in FPC
    return <></>
  }

  return (
    <p className="lastdate" data-swiftype-index="false">
      <MdCalendarToday />
      &nbsp; <T>last-updated</T> {last_updated}
    </p>
  )
}

export default LastUpdatedDate
