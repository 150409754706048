import React, { useContext, useState, useEffect, useRef } from "react"
import { PreferencesContext } from "~context/preferences"

import { container, tabList, tab, selected, panel } from "./tabbed-panels.css"

const TabbedPanels = ({ tabs, syncGroup, defaultTab, children }) => {
  const containerRef = useRef(null)
  const [latestClickedElemOffset, setLatestClickedElemOffset] = useState(0)

  const [localSelectedTab, setLocalSelectedTab] = useState()
  const [isClient, setClient] = useState(false)
  const [prefs, setPrefs] = useContext(PreferencesContext)

  const switchTab = newTabKey => {
    // Store the offset of the container, so we can scroll back to it after redrawing content
    setLatestClickedElemOffset(containerRef.current.offsetTop)

    if (syncGroup) {
      setPrefs(`tabs-${syncGroup}`, newTabKey)
    }
    setLocalSelectedTab(newTabKey)
  }

  useEffect(() => setClient(true), [])

  useEffect(() => {
    if (latestClickedElemOffset) {
      // Calculate the difference between the container's old offset and the new offset
      let newOffset = containerRef.current.offsetTop
      let delta = newOffset - latestClickedElemOffset
      // Scroll window on y axis to compenstate for content shift
      window.scrollBy(0, delta)
    }
  }, [localSelectedTab, latestClickedElemOffset])

  const selectedTabPrecedence = [syncGroup && prefs[`tabs-${syncGroup}`], localSelectedTab, defaultTab, tabs[0].key]
  const selectedTabKey = selectedTabPrecedence.find(k => tabs.find(t => t.key === k))
  const selectedTabIdx = tabs.findIndex(t => t.key === selectedTabKey)

  const panels = React.Children.toArray(children)

  return (
    <div key={`${selectedTabKey}-${isClient}`} className={container} ref={containerRef}>
      <ol className={tabList}>
        {tabs.map((t, idx) => (
          <li
            key={t.key + " " + idx}
            className={idx === selectedTabIdx ? `${tab} ${selected}` : tab}
            onClick={() => switchTab(t.key)}
          >
            {t.label || t.key}
          </li>
        ))}
      </ol>
      <div className={panel}>{panels[selectedTabIdx]}</div>
    </div>
  )
}

export default TabbedPanels