import React from "react"

const Awesome = (props) => {
  let { icon } = props

  if (typeof icon === "undefined" || icon === null) {
    return <></>
  }

  let classes = []
  if (icon.match(/^far-/)) {
    // # force "regular" set
    classes.push("far")
    icon = icon.replace("far-", "")
  } else if (icon.match(/^fas-/)) {
    // # force "solid" set
    classes.push("fas")
    icon = icon.replace("fas-", "")
  } else if (icon.match(/^fab-/)) {
    // # force "brand" set
    classes.push("fab")
    icon = icon.replace("fab-", "")
  } else {
    // # fallback for everything else, previously "fa"
    classes.push("fas")
  }

  classes.push("fa-" + icon)

  if ("extras" in props) {
    let extras = props.extras.split(" ")
    extras.forEach((element) => {
      classes.push("fa-" + element)
    })
  }

  // @TODO
  // tag_parts = []
  // tag_parts << "class=\"#{classes.join(' ')}\""

  // @TODO
  // tag_parts << if @attributes['hidden'] == 'false'
  //                'aria-hidden="false"'
  //              else
  //                'aria-hidden="true"'
  //              end

  // @TODO
  // tag_parts << "alt=\"#{@attributes['alt']}\"" if @attributes.key? 'alt'

  // @TODO
  // tag_parts << "title=\"#{@attributes['title']}\"" if @attributes.key? 'title'

  let classOut = classes.join(" ")

  return (
    <>
      <i className={classOut} aria-hidden="true"></i>
    </>
  )
}

export default Awesome
