// modeled on https://zslabs.com/articles/mdx-link-routing-in-gatsby

import React from 'react';
import PropTypes from 'prop-types'

const MarkdownLink = ({ href, ...rest }) => {

  // Open links to manage.fastly.com and dashboard.signalsciences.net in a new tab or window
  if ((href.startsWith('https://manage.fastly.com')) || (href.startsWith('https://dashboard.signalsciences.net'))) {
    return <a href={href} target="_blank" {...rest} />
  }

  return <a href={href} {...rest} />
}

MarkdownLink.propTypes = {
  href: PropTypes.string.isRequired,
}

export default MarkdownLink
