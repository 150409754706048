import React, { useState, createContext } from "react"
import isClientSide from "../utils/is-client-side"

const LS_KEY = "fastly-docs"

const defaults = (isClientSide() && window.localStorage && JSON.parse(window.localStorage.getItem(LS_KEY))) || {}

const PreferencesContext = createContext([{}, () => {}])

const PreferencesProvider = (props) => {
  const [state, setState] = useState(defaults)

  const setPref = (k, val) => {
    setState((prev) => {
      const newState = { ...prev, [k]: val }
      isClientSide() && window.localStorage && window.localStorage.setItem(LS_KEY, JSON.stringify(newState))
      return newState
    })
  }

  return <PreferencesContext.Provider value={[state, setPref]}>{props.children}</PreferencesContext.Provider>
}

export { PreferencesContext, PreferencesProvider }